jQuery(document).ready(function ($) {
	// COOKIES ++++++++++++++++++++++++++ //

	// melding-cookie wanneer toegestaan
	$('.cookie-accept').on('click', function () {
		$('.melding-cookie').addClass('hidden');
		Cookies.set(
			'cookie.accept',
			'true',
			{ element: '.melding-cookie' },
			{ expires: 365 }
		);
	});

	if (Cookies.get('cookie.accept')) {
		$('.melding-cookie').addClass('keep-hidden');
	}

	// melding-cookie wanneer verborgen
	$('.cookie-hide').on('click', function () {
		$('.melding-cookie').addClass('hidden');
		Cookies.set(
			'cookie.hide',
			'true',
			{ element: '.melding-cookie' },
			{ expires: 30 }
		);
	});

	if (Cookies.get('cookie.hide')) {
		$('.melding-cookie').addClass('keep-hidden');
	}

	// melding-popup
	$('.melding-popup .sluiten').on('click', function () {
		$('.melding-popup').addClass('hidden');
		Cookies.set(
			'melding-popup.hide',
			'true',
			{ element: '.melding-popup' },
			{ expires: 7 }
		);
	});

	if (Cookies.get('melding-popup.hide')) {
		$('.melding-popup').addClass('keep-hidden');
	}

	// melding-boven
	$('.melding-boven .sluiten').on('click', function () {
		$('.melding-boven').addClass('hidden');
		Cookies.set(
			'melding-boven.hide',
			'true',
			{ element: '.melding-boven' },
			{ expires: 7 }
		);
	});

	if (Cookies.get('melding-boven.hide')) {
		$('.melding-boven').addClass('keep-hidden');
	}
});
