import Modal from './modules/modal.js'; //quite
import objectFitImages from './modules/objectfit.js'; //quite
import mobileMenu from './modules/mobile-menu.js'; //quite

import Swiper from './extern/swiper.js'; //quite
import * as Cookie from './modules/cookie.js'; //quite

//Touch sniffer
if ('ontouchstart' in document.documentElement) {
	document.querySelector('html').classList.add('is-touch');
}

// Init functions on pageload
function init() {
	objectFitImages();
	mobileMenu();
	new Modal();
}

jQuery(document).ready(function ($) {
	init();

	// stay tuned -----------
	$('.trig-tuned').on('click', function () {
		$('.stay-tuned').addClass('actief');
	});

	$('.stay-tuned .sluiten').on('click', function () {
		$('.stay-tuned').removeClass('actief');
	});
});

var contentSwiper = new Swiper('.content-container', {
	// Navigation arrows
	navigation: {
		nextEl: '.swiper-button[data-dir=next]',
		prevEl: '.swiper-button[data-dir=prev]'
	},
	pagination: {
		el: '.swiper-pagination'
	}
});

var partnerSwiper = new Swiper('.partner-container', {
	loop: true,
	slidesPerView: 1,
	navigation: {
		nextEl: '.swiper-button[data-dir=next]',
		prevEl: '.swiper-button[data-dir=prev]'
	},
	breakpoints: {
		769: {
			slidesPerView: 2
		},
		1025: {
			slidesPerView: 3
		}
	}
});

var meetSwiper = new Swiper('.meet-container', {
	// Navigation arrows
	loop: true,
	navigation: {
		nextEl: '.swiper-button[data-dir=next]',
		prevEl: '.swiper-button[data-dir=prev]'
	}
});
